<template>
   <the-body></the-body>
</template>

<script>

import TheBody from './Components/Layout/TheBody.vue'
export default ({
   components:{
       TheBody
   }
})
</script>

<style scoped></style>