<template>
    <header>
        <h1>Calculator</h1>
    </header>
</template>

<script>
export default {
     
}

</script>

<style scoped>

header{
    border:2px solid transparent;
    background-color:orangered;
    padding:4px;
    width:620px;
}
header h1{
    color:white;
    font-weight: bolder;
    text-align: center;
}
</style>